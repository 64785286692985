
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map, tap, withLatestFrom, debounceTime, startWith } from 'rxjs/operators';

import { IBreadcrumb } from '../models/breadcrumb.model';


interface IComponentBreadcrumbs {
	component: any;
	crumbs: IBreadcrumb[];
}

@Injectable({
	providedIn: 'root'
})
export class BreadcrumbService {
	private ignoredDepth = 0;
	// NavigationEnd page events
	private pageEvents$ = this.router.events.pipe(filter(event => event instanceof NavigationEnd));
	// Raw breadcrumbs from the router module
	private routingBreadcrumbs$: BehaviorSubject<IBreadcrumb[]> = new BehaviorSubject<IBreadcrumb[]>([]);
	// Components (manual) breadcrumbs
	private _componentsBreadcrumb$: BehaviorSubject<IComponentBreadcrumbs[]> = new BehaviorSubject<IComponentBreadcrumbs[]>([]);
	// Components (manual) breadcrumbs, cleaned when route change
	private componentsBreadcrumb$ = this._componentsBreadcrumb$.pipe(
		withLatestFrom(this.routingBreadcrumbs$),
		map(([forComponents, byRoute]) => {
			const cleanComponents = forComponents.filter(comp => byRoute.find(bc => bc.component === comp.component));
			// reset the subject, if some have not been found
			if (cleanComponents.length !== forComponents.length) {
				this._componentsBreadcrumb$.next(cleanComponents);
			}
			return cleanComponents;
		}),
		debounceTime(0) // smoother flashes (page-with-tabs, etc..)
	);
	// Contextual (optional) crumb
	private contextualBreadcrumb$ = new BehaviorSubject<IBreadcrumb|null>(null);
	// Public observable breadcrumbs
	// Combines routing, components and contextual crumbs
	public breadcrumbs$: Observable<IBreadcrumb[]> = combineLatest([
		this.routingBreadcrumbs$,
		this.componentsBreadcrumb$,
		this.contextualBreadcrumb$]).pipe(
			map(([byRoute, forComponents, forContext]) => {
				// For each "component crumb",
				// find the original one - from its routing definition,
				// and replace it in the breadcrumbs list
				forComponents.forEach(comp => {
					const bcIndex = byRoute.findIndex(bc => bc.component === comp.component);
					if (bcIndex >= 0) {
						// splice this component crumbs in byRoute
						byRoute.splice(bcIndex, comp.crumbs.length, ...comp.crumbs.map(c => {
							// add component so it can be cleaned afterwards
							return { ...c, component: comp.component };
						}));
					}
				});
				// optionnally add the context
				return forContext ? byRoute.concat(forContext) : byRoute;
			}),
			map(crumbs => crumbs.slice(this.ignoredDepth) as IBreadcrumb[]));

	constructor(private activatedRoute: ActivatedRoute, private router: Router) {
		// FIXME: On the first page, this.breadcrumbs$ doest not emit
		// if this.routingBreadcrumbs$ is a simple piped observable
		this.pageEvents$.pipe(
			startWith(null),
			tap(_ => this.contextualBreadcrumb$.next(null)),
			tap(_ => this._componentsBreadcrumb$.next([])),
			map(_ => this.buildBreadcrumb(this.activatedRoute.root)),
			map(crumbs => this.routingBreadcrumbs$.next(crumbs))
		).subscribe();
	}

	// Modify the target component crumb
	public setComponentCrumbs(component: any, crumbs: IBreadcrumb[]) {
		const otherCrumbs = this._componentsBreadcrumb$.getValue().filter(c => component !== c.component);
		this._componentsBreadcrumb$.next([
			...otherCrumbs,
			{ component, crumbs }
		]);
	}

	// Modify the context crumb
	public setContextCrumb(crumb: IBreadcrumb) {
		this.contextualBreadcrumb$.next(crumb);
	}

	// Build a breadcrumb array from route definitions
	private buildBreadcrumb(route: ActivatedRoute, root = '/', breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {
		// Verify this is the primary route
		if (route.outlet !== PRIMARY_OUTLET) {
			return breadcrumbs;
		}
		// If no routeConfig is avalailable we are on the root path
		const onRoot = !route.routeConfig || !route.routeConfig.data;
		if (!onRoot) {
			// Get the crumb label from the route config data
			const titleTranslationKey = route.routeConfig.data?.titleTranslationKey || 'Home';
			const label = `PageTitles.${titleTranslationKey}`;
			// Detect cases when a container has the same title as the routed child
			const duplicate = breadcrumbs.length && breadcrumbs[breadcrumbs.length - 1].label === label;
			if (!duplicate && route.snapshot) {
				// Rebuild the complete path each time
				const url: string = root + this.getResolvedUrl(route.snapshot);
				// Add the new crumb, using route snapshot params
				const crumb = {
					url,
					label,
					labelParams: { data: route.snapshot.params },
					hasTranslationKey: true,
					component: route.component
				};
				breadcrumbs.push(crumb);
			}
		}
		if (route.firstChild) {
			// If we are not on our current path yet,
			// there will be more children to look after, to build our breadcumb
			return this.buildBreadcrumb(route.firstChild, root, breadcrumbs);
		}
		return breadcrumbs;
	}

	// rebuild the full URL from a snapshot
	// https://stackoverflow.com/a/53429547/1689894
	private getResolvedUrl(route: ActivatedRouteSnapshot): string {
		return route.pathFromRoot
			.map(v => v.url.filter(s => !!s.path).map(segment => segment.toString()).join('/'))
			.filter(s => !!s)
			.join('/');
	}

	public setIgnoredDepth(depth: number) {
		this.ignoredDepth = depth;
	}
}
